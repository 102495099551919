<template>
  <div>
    <!-- /Brand logo-->
    <!-- Left Text-->
    <div class="col-lg-8 mt-3 p-5">
      <h2 class="text-center text-primary mb-2">
        Política de Termos e Uso
        <div class="float-right mb-2">
          <b-button
            variant="relief-primary"
            @click.stop="$router.push({ name: 'auth-login' })"
          >
            Voltar
          </b-button>
        </div>
      </h2>

      <h4 class="text-center">
        Sobre a ContasInfinity
      </h4>
      <br>
      <p class="text-justify">
        Este site pertence e é operado por ContasInfinity.com.br . Estes Termos
        estabelecem os termos e condições sob os quais você pode utilizar nosso
        site e serviços oferecidos por nós. Este site oferece aos visitantes
        serviços relacionado a SMS e contas para as plataformas sociais atuais.
        Ao acessar ou usar o website do nosso serviço, você aprova que leu,
        entendeu e concorda em ficar vinculado a estes Termos.
      </p>
      <br>
      <!-- <p>Você não tem permissão para usar este website e / ou receber serviços se isso for proibido em seu país ou sob qualquer lei ou regulamento aplicável a você.</p> -->
      <h4 class="text-center">
        Sobre a compra dos serviços ofertados, termos de uso e privacidade
      </h4>
      <br>
      <p class="text-justify">
        Ao comprar um item, você concorda que: (i) é responsável por ler a
        listagem completa do item antes de assumir o compromisso de comprá-lo:
        (ii) aceita que todo o dinheiro que é depositado no site tem por
        consequente gastos e que caso queira a devolução do mesmo, isso não será
        feito de forma total do valor que foi adicionado, ficando a cargo do
        site a quantidade percentual que deve ser devolvida: (iii) aceita que
        está ciente dos serviços ofertados pelo site. (vi) aceita que todo o
        dinheiro que foi gasto com os serviços dentro do site não será reposto
        em caso de pedido sem argumentos que comprovem a existências de bugs,
        falhas ou outra forma ocorrência que venha a diminuir seu saldo atual
        indevidamente ou injustamente. (v) aceita que as informações aqui
        explicitadas são devidamente lidas. (vi) autoriza o site a mandar
        e-mails quando pedido pelo usuário e que a ContasInfinity pode vir a
        oferecer serviços via e-mail ou autoriazação para mandar e-mail ao
        usuário diante de pedido previamente estabelecido. (vii) confere que
        está ciente da existência dos serviços de comunicação ligados a
        ContasInfinity para fins de ajuda e suporte no uso do website e que o
        mesmo serviço pode funcionar em alguns horários e em outros não, cabendo
        aos operadores a decisão de atendimento imediato ou não.
      </p>
      <h4 class="text-center">
        Regras do site ContasInfinity.com.br
      </h4>
      <br>
      <h5>1. Regras e regulamentos gerais.</h5>
      <br>
      <p class="text-justify">
        1.1. O serviço ContasInfinity.com é um serviço de ativação automática de
        conta, ou seja, disponibiliza SMS para os números desta empresa para
        ativação de contas de diversos recursos, e também oferece a oportunidade
        de adquirir contas prontas de alguns serviços. A empresa ContasInfinity
        não assume qualquer responsabilidade pelas contas ativadas para os
        números recebidos neste serviço, pela sua perda, pirataria e quaisquer
        perdas materiais ocorridas após a ativação destas contas ou solicitação
        em nosso serviço de seguidores. Além disso, a empresa não assume
        qualquer responsabilidade por quaisquer ações e métodos de uso de
        números e contas ou serviços comprados (Seguidores).<br>
        1.2. Qualquer tentativa de fraude, desde o uso de "bugs" encontrados no
        sistema, etc. a fraude financeira, é a mais estrita violação das regras
        e necessariamente acarreta uma penalidade que varia de uma proibição de
        vida a responsabilidade criminal.<br>
        1.3. Qualquer tentativa de violar as leis de qualquer país usando o
        serviço ContasInfinity.com é estritamente proibida. ContasInfinity está
        comprometido com negócios legais e conduta legal. Se a empresa
        ContasInfinity detectar quaisquer tentativas suspeitas ou ações
        bem-sucedidas que violem as leis de qualquer país, os funcionários da
        empresa sem falta enviarão os materiais às autoridades competentes.<br>
        1.4. A empresa ContasInfinity reserva-se o direito total de alterar,
        corrigir, adicionar e remover as regras desta seção.
      </p>
      <p class="text-justify" />
      <h5>2. Regras para apresentar um recurso.</h5>
      <br>
      2.1. Se precisar entrar em contato com o serviço de suporte, você pode
      fazê-lo usando a seção "ajuda" - http://contasinfinity.com/ajuda.html e
      "vá para o site de suporte técnico". Lá você também pode ver as respostas
      às suas solicitações.<br>
      2.2. Quanto mais precisamente e com mais detalhes você descrever a
      essência de sua situação, bem como se anexar capturas de tela
      esclarecedoras, mais rápido seu problema será resolvido.<br>
      2.3. O uso de linguagem chula, linguagem vulgar, bem como insultos ao
      ContasInfinity e seus funcionários é uma violação grave e pode ser punida
      na forma de multa debitada do saldo do usuário (a critério do serviço),
      até uma conta proibição (temporária e vitalícia).). Para tickets de spam,
      a pergunta será ignorada.
      <p class="text-justify" />
      <h5>3. Usando a funcionalidade do serviço ContasInfinity.com.</h5>
      <br>
      <h6>3.1. Ativações.</h6>
      <br>
      3.1.1. A ativação deve ser realizada de acordo com as instruções
      especificadas na seção "ajuda" - http://contasinfinity.com/ajuda.html.
      Para obter mais informações, você pode entrar em contato com o serviço de
      suporte técnico. Para as regras de apresentação de um recurso, ver a
      cláusula 2.<br>
      3.1.2. O ContasInfinity pode alterar as instruções e a funcionalidade das
      ativações a seu critério. Informações sobre as novidades estão na seção
      "notícias" - http://ContasInfinity.com/index.php?do=lastnews.<br>
      3.1.3. O uso de ativações para ações ilegais e ações que violam as leis de
      qualquer país viola a "Cláusula 1. Regras e regulamentos gerais, seção
      1.3." e tem a responsabilidade especificada nesta seção.<br>
      3.1.4. O serviço de recebimento de SMS repetidos na seção Histórico não é
      garantido, pois o número pode ser removido do sistema a qualquer momento
      após o encerramento do pedido<br>
      3.1.5. O serviço ContasInfinity oferece apenas o serviço de recebimento de
      SMS. O serviço não é responsável pelas ações do usuário para registro ou
      verificação.<br>
      3.1.6. Os números de ativação são apenas para uso de curto prazo. O
      serviço ContasInfinity não se responsabiliza pela posterior utilização do
      número (reemissão, revenda).<br>
      3,3. Loja de conta.<br>
      3.3.1. A compra de contas da "Loja de contas ContasInfinity.com" deve ser
      feita de acordo com as instruções na seção "ajuda" -
      http://contasinfinity.com/ajuda.html. Para obter mais informações, você
      pode entrar em contato com o serviço de suporte técnico. Para as regras de
      apresentação de um recurso, ver a cláusula 2.<br>
      3.3.2. O ContasInfinity pode alterar as instruções e a funcionalidade do
      armazenamento da conta a seu critério. Informações sobre as novidades
      estão na seção "notícias" - http://ContasInfinity.com/painel/index.php.<br>
      3.3.3. Usar contas de loja para ações ilegais e ações que violam as leis
      de qualquer país viola a "Cláusula 1. Regras e regulamentos gerais, seção
      1.3." e tem a responsabilidade especificada nesta seção.<br>
      3.4.2. Você pode alterar o endereço IP de autorização no máximo uma vez
      por dia.<br>
      3.4.3. O uso de proxies 4G / LTE para ações ilegais e ações que violam as
      leis de qualquer país viola a "Cláusula 1. Regras e regulamentos gerais,
      seção 1.3." e tem a responsabilidade especificada nesta seção.
      <p class="text-justify" />
      <h5>4. Retorne.</h5>
      <br>
      4.1. Os pedidos de reembolso por erros e avarias na operação de ativações
      são feitos no serviço de suporte. Para as regras de arquivamento, consulte
      a cláusula 2.<br>
      4.2. Os pedidos de reembolso de contas da "Loja de contas" devem ser
      criados no serviço de suporte apenas se você tiver certeza de que isso não
      viola os termos da oferta - http://contasinfinity.com/new-rules.html.<br>
      4.3. Todos os pedidos de devolução são considerados individualmente e o
      ContasInfinity reserva-se o direito de recusar a devolução sob certas
      condições (por exemplo, violação dos termos da oferta).<br>
      4,4. Reembolsos em sua conta pessoal (não na conta ContasInfinity) são
      possíveis apenas em MercadoPago e Paypal com um saldo de mais de 10 Reais.
      Revisado em 30 dias.
      <p class="text-justify" />
      <h5>5. Parceria.</h5>
      <br>
      5.1. Para todas as questões de parceria, entre em contato com o serviço de
      suporte. Para as regras de arquivamento, consulte a cláusula 2.<br>
      5,2 ContasInfinity reserva-se o direito de alterar os termos da parceria e
      rescindi-la a seu exclusivo critério.
      <p class="text-justify" />
      <h5>6. Contas.</h5>
      <br>
      6.1. O tempo de garatia é especificado no momento da compra. Deverá ser
      comunicado qualquer problema dentro do prazo de até 24 horas após a compra
      de qualquer tipo de conta dentro de nosso site pelo meio de Ticket.<br>
      6.2. Não nos responsabilizamos sobre o mal uso e vazamento de informações
      das contas compradas em nosso site.<br>
      6.3 ContasInfinity reserva-se o direito de alterar os termos de contas e
      rescindi-la a seu exclusivo critério.
      <p class="text-justify" />
      <h5>7. Avisos Imporantes.</h5>
      <br>
      7.1. A Opção de SMS online ContasInfinity é fornecer um número virtual e
      um código. Não somos responsáveis por contas registradas, uma vez que
      muitos fatores influenciam o banimento. Se o número não for adequado para
      você, você sempre pode pressionar o botão "cancelar". Não há reembolso
      para contas banidas.<br>
      7.2. Proibido o uso do serviço para assinaturas pagas.<br>
      7.3 Você pode usar o número de telefone para verificar o serviço comprado
      apenas na conta; SMS diferentes serão rejeitados.<br>
      7.4. Seu saldo é descontado caso ultrapasse o tempo limite de 10 min com o
      número comprado. Lembre-se sempre de cancelar o número dentro do tempo
      estabelecido.<br>
      7.5. Utilizar de bugs no site poderá ocorrer banimentos sem aviso prévio e
      a retenção do saldo e suas respectivas compras.<br>
      7.6 Ao se deparar com algum bug, é sua obrigação nos comunicar
      imediatamente, caso contrário poderá sofrer punições.
      <p class="text-justify" />
      <h5>POLÍTICA DE REEMBOLSO</h5>
      <h6>DEVOLUÇÃO DO DINHEIRO</h6>
      Será reembolsado caso esteja dentro de nossas diretrizes e reembolsamos
      apenas o último saldo adicionado!
      <p><strong>Requisitos para o Reembolso:</strong></p>
      <p>
        8.1 - Ser solicitado dentro de um prazo de até 7 dias do último saldo
        adicionado [Art. 49 - Direito ao arrependimento de compra].
      </p>
      <p>
        8.2 - Não ter gasto / usado o último saldo adicionado em algum produto
        ou serviço do site [Se você tentou comprar SMS e não chegou o código,
        mesmo assim você cancelou o manteve o saldo isso não caracteriza como
        possível reembolso].
      </p>
      <p>
        8.3 - O reembolso é feito pelo mesmo meio de pagamento utilizado pelo
        mesmo e direcionado apenas a mesma pessoa que fez a compra com as mesmas
        informações. Não reembolsamos para outras pessoas.
      </p>
      <p>
        8.4 - Não reembolsamos situações de mal uso ou falta de informação:
        Comprar e acontecer quedas massivas (acima de 7) de contas ou por falta
        de estoque. [Ao comprar você está ciente dessas informações].
      </p>
      <p>
        8.5 - Não reembolsamos se for detectado mal uso (Requisito 4º),
        utilização de bugs, ou má fé e ameaças por parte do usuários.
      </p>
      <p>8.6 - Ao pedir reembolso você terá todo o saldo removido.</p>
      <p>
        8.6 - Ao pedir reembolso seu indicador (quem indicou você) terá o saldo
        bônus removido da conta.
      </p>
      <p>
        8.8 - Ao pedir reembolso todos os dados dentro da ContasInifinity serão
        apagados e usuário não terá mais acesso além de ser banido
        permanentemente do site.
      </p>
      <p>
        8.9 - Caso sua solicitação esteja de acordo com nossas regras, iremos
        fazer o reembolso em até 3 dias uteis.
      </p>
      <p class="text-justify" />
      <h5>9.POLÍTICA DE INDICAÇÃO</h5>
      <br>
      <h6>DOS INDICADOS OU AFILIADO</h6>
      Caracteriza-se um indicado aquela pessoa que recebeu um link de referência
      e cadastrou-se no site com esse mesmo link.<br>
      Os indicados tem por base os mesmos Termos de Uso apresentado no início
      desse documento.<br>
      <br>
      <h6>DOS INDICANDOS OU INDICADOR</h6>
      Caracteriza-se como indicando ou indicador aquela pessoa cujo link foi
      enviando ao indicado para que o cadastro no site fosse efetuado.<br>
      Os indicandos tem por base os mesmos Termos de Uso apresentado no início
      desse documento.<br>
      <br>
      <h6>DA CONDUTA</h6>
      Os indicandos e indicados, confirmam, ao aceitar os termos, seguir as
      regras diante do previsto a partir do capítulo 1 desse documento;<br>
      Alegam que qualquer má conduta com fins de tirar proveito ou lesar a
      ContasInfinity estará ciente que será banido sem aviso prévio e que sua
      conta e saldo não serão recuperados de forma alguma.<br>
      <br>
      <h6>DA COMISSÃO</h6>
      Entende-se por comissão o valor resultante da multiplicação percentual
      pelo primeiro deposito em que indicado fizer.<br>
      Com a taxa atual de 5% do saldo se um indicado depositar R$10,00 você
      receberá 0.50 centavos que é 5% do primeiro depósito do indicado.<br>
      <br>
      <h6>DO FUNCIONAMENTO</h6>
      Você copia o seu link de link referência e envia para seus amigos e conhecidos.<br>
      O seu amigo irá se cadastrar pelo seu link e adicionar um saldo no site e posteriormente gastar esse saldo.<br>
      Você irá ganhar 5% de todo saldo gasto pelo seu amigo dentro da plataforma.<br>
      O saldo gasto pelo seu indicado ficará no seu Saldo a liberar relacionado aos indicados, sendo assim contado 10 dias até ser transferido para o Saldo Disponível;<br>
      O saldo poderá ser trocado ou sacado para uma chave PIX ou saldo dentro de nosso site;<br>
      O limite mínimo para o saque é está disposto na página "Meus Afiliados" na seção Área de Indicações do site.<br>
      A ContasInfinity reserva o direito de alterar sem aviso prévio a taxa percentual de 5%, apresentada anteriormente, a qualquer momento, bem como o limite mínimo.<br>
      A comissão do indicador será liberada como saldo de comissão a partir de 10 dias úteis da data da compra de seu indicado.
      <p>
        A ContasInfinity se reserva no direito e alterar sem aviso prévio esse documento de Termos de Uso. Cabe ao usuário ler atentamente esse documento.
      </p>
      <h3 class="text-primary">
        Atenciosamente, ContasInfinity!
      </h3>
    </div>
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'

export default {
  components: {
    BButton
  }
}
</script>
